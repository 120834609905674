import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

import apiFetch from '@wordpress/api-fetch'

export default class Home {
    constructor() {
        Home.sliderBlog()
        Home.sliderTempsFort()
        Home.instaWall()
    }

    static async sliderBlog() {
        const selector = '[data-element="carousel-home-blog"]'

        if ($(selector).length) {
            $(selector).each(async function () {
                await Swiper.create(this, {
                    slidesPerView: 1,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    speed: 600,
                    lazy: true,
                    watchSlidesProgress: true,
                    navigation: {
                        nextEl: '.strate__blog__slider__nav',
                    },
                    spaceBetween: 10,
                    freeMode: {
                        enabled: true,
                        sticky: true,
                    },
                    grabCursor: true,
                    breakpoints: {
                        650: {
                            spaceBetween: 0,
                        },
                    },
                })
            })
        }
    }

    static async sliderTempsFort() {
        const selector = '[data-element="carousel-home-temps-fort"]'

        if ($(selector).length) {
            $(selector).each(async function () {
                const slider_strate5 = await Swiper.create(this, {
                    slidesPerView: 1,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    pagination: {
                        el: '.strate__agenda__heading__pagination',
                        type: 'bullets',
                        clickable: true,
                    },
                    speed: 600,
                    lazy: true,
                    watchSlidesProgress: true,
                    spaceBetween: 0,
                    freeMode: {
                        enabled: true,
                        sticky: true,
                    },
                    grabCursor: true,
                })

                const pagination_agenda = $('.strate__agenda__heading__pagination')

                //Init active cursor
                $(pagination_agenda).append(
                    '<span class="swiper-pagination-bullet-active-cursor"></span>',
                )
                const cursor__agenda = $(pagination_agenda).find(
                    '.swiper-pagination-bullet-active-cursor',
                )
                let bullet_pos_agenda = $(pagination_agenda)
                    .find('.swiper-pagination-bullet-active')
                    .position().left
                $(cursor__agenda).css('left', bullet_pos_agenda + 10)
                $(cursor__agenda).css('opacity', 1)

                //Anim on change
                slider_strate5.on('slideChange', () => {
                    bullet_pos_agenda = $(pagination_agenda)
                        .find('.swiper-pagination-bullet-active')
                        .position().left
                    $(cursor__agenda).css('left', bullet_pos_agenda + 10)
                })

                //Anim on resize
                $(window).on('resize', () => {
                    bullet_pos_agenda = $(pagination_agenda)
                        .find('.swiper-pagination-bullet-active')
                        .position().left
                    $(cursor__agenda).css('left', bullet_pos_agenda + 10)
                })
            })
        }
    }

    static instaWall() {
        const social_wall = '[data-id="social-wall"]'

        if ($(social_wall).length > 0 && !$(social_wall).is(':hidden')) {
            apiFetch({
                path: Customer.irisSsoInstagramPath,
                method: 'POST',
                data: { limit: 4 },
            })
                .then((result) => {
                    $(social_wall).html(result.render)
                    import(
                        '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component' /* webpackChunkName: "scripts/hc_lazyload" */
                    ).then(({ HcLazyload }) => {
                        HcLazyload.update()
                    })
                })
                .catch(() => {
                    $(social_wall).html(Customer.irisSsoInstagramError)
                })
        }
    }
}
